import React from "react";
import ParagonLogo from "./assets/paragon-corp.png";
import DeloitteLogo from "./assets/deloitte.svg";
import ParagonFactory from "./assets/paragon-factory.jpg";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import WardahLogo from "./assets/wardah.png";
import MakeOverLogo from "./assets/makeover.png";
import EminaLogo from "./assets/emina.png";
import KahfLogo from "./assets/kahf.png";
import PutriLogo from "./assets/putri.png";
import CrystallureLogo from "./assets/crystallure.png";
import InstaperfectLogo from "./assets/instaperfect.png";
import LaboreLogo from "./assets/labore.png";
import BiodefLogo from "./assets/biodef.png";
import TaviLogo from "./assets/tavi.png";
import WonderlyLogo from "./assets/wonderly.png";
import OMGLogo from "./assets/omg.png";
import BeyondlyLogo from "./assets/beyondly.png";
import EarthLoveLifeLogo from "./assets/earthlovelife.png";
import "./App.css";
import { useMediaQuery } from "@chakra-ui/react"; // Import

export default function App() {
  const [isMobile] = useMediaQuery("(max-width: 48em)");
  const listBrand = [
    { src: WardahLogo, alt: "Wardah" },
    { src: MakeOverLogo, alt: "Make Over" },
    { src: EminaLogo, alt: "Emina" },
    { src: KahfLogo, alt: "Kahf" },
    { src: PutriLogo, alt: "Putri" },
    { src: CrystallureLogo, alt: "Crystallure" },
    { src: InstaperfectLogo, alt: "Instaperfect" },
    { src: LaboreLogo, alt: "Labore" },
    { src: BiodefLogo, alt: "Biodef" },
    { src: TaviLogo, alt: "Tavi" },
    { src: WonderlyLogo, alt: "Wonderly" },
    { src: OMGLogo, alt: "OMG" },
    { src: BeyondlyLogo, alt: "Beyondly" },
    { src: EarthLoveLifeLogo, alt: "Earth Love Life" },
  ];
  return (
    <Flex direction="column" minHeight="100vh">
      <Flex backgroundColor="#215AA8">
        <Container
          maxW="container.xl"
          display="flex"
          justifyContent="space-between"
        >
          <Flex
            justifyContent="center"
            flexDirection="row"
            height="96px"
            alignItems="center"
          >
            <Image src={ParagonLogo} height={{ base: "40px", md: "72px" }} />
          </Flex>
          <Flex
            justifyContent="center"
            flexDirection="row"
            height="96px"
            alignItems="center"
          >
            <Image src={DeloitteLogo} height={{ base: "20px", md: "36px" }} />
          </Flex>
        </Container>
      </Flex>
      <Flex
        flexGrow={1}
        bg="blue.600"
        color="white"
        position="relative"
        alignItems="center"
        height="25%"
      >
        <Box
          position="absolute"
          inset={0}
          bgImage={ParagonFactory}
          bgSize="cover"
          backgroundPosition="0px 65%"
          opacity={0.2}
        />
        <Container
          id="main-content"
          maxW="container.xl"
          py={16}
          position="relative"
          zIndex={1}
        >
          <VStack align="center" spacing={4}>
            <Heading
              as="h1"
              className="main-title"
              fontWeight="bold"
              textAlign="center"
            >
              Paragon {isMobile && <br />} Speak Up
            </Heading>
          </VStack>
        </Container>
      </Flex>
      <Flex bg="white" flexGrow={2}>
        <Container
          id="main-content"
          maxW="container.xl"
          py={16}
          position="relative"
          zIndex={1}
        >
          <Heading
            as="h3"
            fontSize="36px"
            fontWeight="semibold"
            color="#215AA8"
          >
            Berani Bicara, Jaga Integritas!
          </Heading>
          <Text
            fontSize="lg"
            color="#215AA8"
            marginBottom="24px"
            marginTop="16px"
          >
            Paragon Speak Up adalah sistem pelaporan yang dirancang untuk
            menerima laporan secara anonim dan rahasia dari seluruh karyawan dan
            pihak eksternal terkait dugaan pelanggaran etika dan hukum, baik
            yang terjadi di internal maupun terkait dengan mitra eksternal
            Paragon.
          </Text>

          <Text fontSize="lg" color="#215AA8" marginBottom="24px">
            Berani bicara adalah wujud nyata dari komitmen bersama dalam
            menjunjung integritas dan keadilan di Paragon. Mari kita jaga budaya
            kerja yang sehat dan profesional demi kebermanfaatan dan
            keberlanjutan Paragon. Jadilah bagian dari perubahan ini!
          </Text>
          <Flex justifyContent="center">
            <a
              href="https://idn.deloitte-halo.com/paragonspeakup/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                size="lg"
                background="#86BC25"
                color="#222222"
                px={8}
                fontWeight="semibold"
              >
                LAPORKAN
              </Button>
            </a>
          </Flex>
        </Container>
      </Flex>

      <Box as="footer" bg="white" py={4}>
        <Container maxW="container.xl">
          <Flex wrap="wrap" justify="center" align="center" gap={4}>
            {listBrand.map((brand, index) => (
              <Image key={index} src={brand.src} alt={brand.alt} width={100} />
            ))}
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
}
